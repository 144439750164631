.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.plan-container{
  height: 100%;
  padding: 0.5em 1em; 
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  border: 3px solid transparent;
  cursor: pointer;
}
.plan-container.active{
  border: 3px solid #ea5348;
}
.plan-container:hover{
  border: 3px solid #ea5348;
}
.text-center{
  text-align: center;
}
.plan-container div.feature{
  border-top: 1px solid grey;
  padding: 0.2em
}
.plan-container div.last{
  border-bottom: 1px solid grey;
  margin-bottom: 2em;
}


.success-message{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.success-message .container{
  text-align: center;
}

        