.promo-codes{
  text-align: center;
  cursor: pointer;
  font-size: 1.2em;
  
}
.promo-codes.active{
  font-weight: bold;
}
.promo-codes:hover{
  text-decoration: underline;
}
.separator {
  height: 2px;
  background-color: #ea5348; 
  border: none;
}

.cursor-pointer{
  cursor: pointer;
}
.d-flex{
  display: flex;
}
.justify-content-center{
  justify-content: center;
}
.align-items-center{
  align-items: center;
}
.justify-content-between{
  justify-content: space-between;
}
.justify-content-end{
  justify-content: flex-end;
}

.flickread-toast-message div{
  background-color: #ea5348;
  color: white;
  font-weight: bold;

}


.flickread-toast-login div{
  background-color: white;
  color: #ea5348;
  font-weight: bold;
}
