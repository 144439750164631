.StripeCheckout{
  background: transparent !important;
  box-shadow: none !important;
}

.StripeCheckout span{
  background: #ea5348 !important;
  font-size: 1rem;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif  !important;
  height: 35px !important;
  display: flex !important;
  align-items: center !important;
  border-radius: .28571429rem !important;
  text-shadow: none !important;
}